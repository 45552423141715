import {
  Grid,
  GridColumn,
  ModernaTheme,
  Text,
  ThemeOverride,
  ThemeProvider
} from "@modernatx/ui-kit-react";
import React from "react";
import { Global } from "theme-ui";

import { RenderMetadata } from "@/components/RenderMetadata";
import { RenderContent } from "@/components/rsv/RenderContent";
import { ExperienceProvider } from "@/context/ExperienceContext";
import { ExitModalProvider } from "@/hooks/useExitModal";
import { NavPanelProvider } from "@/hooks/useNavPanel";
import { JPCovidTheme, USCovidTheme } from "@/profiles/themes";
import { BlockPageProps } from "@/types/Block";
import { Experience } from "@/utils/experience";

interface CovidPageProps {
  content: BlockPageProps | null;
  experience: Experience | null;
}
export const CovidPage: React.FC<CovidPageProps> = ({ content, experience }) => {
  const { country } = experience || {};

  const background = (
    <Global
      styles={{
        html: {
          backgroundColor: "white"
        }
      }}
    />
  );

  if (!country || !content) {
    return (
      <ThemeProvider>
        {background}
        <Grid>
          <GridColumn
            sx={{
              alignItems: "center",
              display: "flex",
              height: "100vh",
              justifyContent: "center"
            }}
          >
            <Text size="lg" as="p">
              The site is not available in your country.
            </Text>
          </GridColumn>
        </Grid>
      </ThemeProvider>
    );
  }
  const experienceData = experience ?? { country: "us" };

  const theme = (() => {
    switch (content.theme) {
      case "jpCovid":
        return JPCovidTheme;
      case "covid":
        return USCovidTheme;
      default:
        return ModernaTheme;
    }
  })();

  return (
    <ExperienceProvider experience={experienceData}>
      <ThemeProvider theme={theme} usingStyleInheritance={true}>
        <ThemeOverride mode={content.themeMode}>
          <Global
            styles={{
              html: {
                backgroundColor: content.backgroundColor as string
              }
            }}
          />
        </ThemeOverride>
        <NavPanelProvider>
          <ExitModalProvider exitModal={content.exitModal}>
            <RenderMetadata {...content.metadata} />
            <RenderContent content={content} />
          </ExitModalProvider>
        </NavPanelProvider>
      </ThemeProvider>
    </ExperienceProvider>
  );
};
